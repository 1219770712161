import { useState } from "react"
import FileUpload from "./FileUpload"
import ImageView from "./ImageView"
import Title from "./Title"

const ImageCleaner = () => {
  const [previewImage] = useState<string | null>(null)

  return (
    <div className='p-8 w-full flex flex-col h-full gap-8'>
      <Title />
      <div className='w-full flex flex-row gap-4 h-[calc(100%_-_105px)]'>
        <FileUpload />
        <div className='bg-border-secondary h-full w-[1px] mx-2' />
        <ImageView imgSrc={previewImage} />
      </div>
    </div>
  )
}

export default ImageCleaner